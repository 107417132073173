import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';

export const BorderlessCard = ({ post, buttonText }) => (
  <Col className="p-3 d-flex flex-column">
    <h4
      className="flex-grow-1"
      dangerouslySetInnerHTML={{
        __html: post.title.rendered,
      }}
    />
    <img src={post.acf.image} alt={post.title.rendered} className="w-100 mb-2" />
    <p>{post.acf.description}</p>
    <Button href={post.acf.url} target="_blank" block>
      {buttonText}
    </Button>
  </Col>
);

BorderlessCard.propTypes = {
  post: PropTypes.object,
  buttonText: PropTypes.string,
};
