import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const PaypalFieldContainer = ({
  controlId,
  label,
  invalid,
  containerId,
  autocomplete,
  validationMessage,
  groupClassName = '',
  className = 'form-control paypal-field',
}) => (
  <Form.Group className={groupClassName} controlId={controlId}>
    {label && (
      <div className="form-label" aria-hidden>
        {label}
      </div>
    )}

    <div className={`${className} ${invalid ? 'is-invalid' : ''}`} id={containerId} data-autocomplete={autocomplete}>
      {/* PayPal will insert field here */}
    </div>

    {invalid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
  </Form.Group>
);

PaypalFieldContainer.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  containerId: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
};
