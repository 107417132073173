import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { t } from '../../../text';
import { doesCookieExist, setCookie } from '../../utils/cookies';

const ACCEPT_SITE_COOKIE = 'acceptSiteCookies';

export const CookieConsent = () => {
  const hasAcceptedCookies = doesCookieExist(ACCEPT_SITE_COOKIE);
  const [showConsent, toggleConsent] = useState(!hasAcceptedCookies);

  const acceptCookie = () => {
    setCookie(ACCEPT_SITE_COOKIE, 365);
    toggleConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <Container fluid className="cookie-consent border-top">
      <Container>
        <Row>
          <Col md={7} lg={10} className="d-flex align-items-center">
            <p className="m-md-0">
              {t('footer.cookieConsent.text')} For more details view our{' '}
              <NavLink to="/privacy-policy">Privacy &amp; Cookies Policy.</NavLink>
            </p>
          </Col>
          <Col md={3} lg={2} className="d-flex align-items-center">
            <Button type="button" size="sm" block onClick={() => acceptCookie()}>
              OK
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
