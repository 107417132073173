import PropTypes from 'prop-types';
import { Nav, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import{ Icon } from '../Icon';
import t from '../../../text';
import { useShowOriginalBackground } from './useShowOriginalBackground';

export const HeaderNavLoginButtons = ({ loggedIn, onLogout, onShowLogin }) => {
  const { showOriginalBackground } = useShowOriginalBackground();

  return (
    <Nav>
      {loggedIn ? (
        <>
          <NavLink
            to="/my-account"
            className={`btn btn-darker btn-sm mr-0 mr-lg-3 mb-3 mb-lg-0 mt-4 mt-lg-0 ml-2 ${showOriginalBackground ? 'd-block' : 'd-none'}`}
          >
            {t('header.myAccount')}
          </NavLink>
          <Button
            type="button"
            size="sm"
            className={`mb-2 mb-lg-0 ${showOriginalBackground ? 'd-block' : 'd-none'}`}
            variant="darker"
            onClick={onLogout}
          >
            {t('header.logout')}
          </Button>
        </>
      ) : (
        <Button
          type="button"
          size="sm"
          className={showOriginalBackground ? 'd-block' : 'd-none'}
          variant="darker"
          onClick={onShowLogin}
        >
          <Icon icon="sign-in" /> {t('header.myAccount')}
        </Button>
      )}
    </Nav>
  );
};

HeaderNavLoginButtons.propTypes = {
  loggedIn: PropTypes.bool,
  onLogout: PropTypes.func,
  onShowLogin: PropTypes.func,
};
