import { NavLink } from 'react-router-dom';

import { A } from '../A';

export const FooterNavLink = ({ external, path, title }) => {
  if (external) {
    return (
      <A href={path} className="d-block text-secondary">
        <small>{title}</small>
      </A>
    );
  }
  return (
    <NavLink to={path} className="d-block text-secondary">
      <small>{title}</small>
    </NavLink>
  );
};
