import { Row, Col, Container } from 'react-bootstrap';

import t from '../../../text';
import { Logo } from '../Logo';
import stars from '../../assets/images/yellow-stars.svg';
import greenStar from '../../assets/images/green-star.svg';

export const Reviews = () => {
  const reviews = [
    {
      body: 'I have an account with Breakfree which makes everything very simple and have no hesitation in recommending them. My Husband and I have been on several holidays with them over the years and hope to continue.',
      name: 'Mrs Sulley',
    },
    {
      body: 'Easy to use website and find a break in area and on dates wanted. Good information given about parks. Pleased with experience and would recommend and reuse service.',
      name: 'Miss Abraham',
    },
    {
      body: 'I found this experience excellent. l am not good on the computer but found it easy enough and look forward to our break away, thank you!',
      name: 'Mrs Mower',
    },
  ];

  return (
    <Container>
      <Row>
        <Col className="col-two-fifths">
          <Logo variant="dark" height={40} />
          <h2 className="h4 text-white mt-3">
            <strong>{t('footer.trust.title')}</strong>
          </h2>
          <p className="text-white">{t('footer.trust.title2')}</p>
          <div className="m-0 mt-3">
            <a
              href="https://uk.trustpilot.com/review/www.breakfreeholidays.co.uk?stars=5"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center text-white"
            >
              See our reviews on <img src={greenStar} alt="Trustpilot star" width="22px" className="mx-1 mb-1" />{' '}
              Trustpilot
            </a>
          </div>
        </Col>
        <Col>
          <p className="text-white">{t('footer.reviews.recentComments')}</p>
          <Row>
            {reviews.map((review, i) => (
              <Col key={i} md={4} className="mb-3">
                <img src={stars} alt="5" className="d-block star-styling mb-2" />
                <p className="text-white text-sm mb-1">{review.body}</p>
                <p className="text-white">
                  <small>{review.name}</small>
                </p>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
