import Slider from 'react-slick';
import { useGetCurrentSlides } from './useGetCurrentSlides';
import { Children, forwardRef } from 'react';

export const CustomReactSlider = forwardRef(({ className, children, ...settings }, ref) => {
  const sliderSettings = { ...settings };
  const childrenLength = Children.count(children);

  const { getCurrentSlides } = useGetCurrentSlides();
  const slidesToShow = getCurrentSlides(sliderSettings);

  // Don't show arrows when only 1 child, more importantly set infinite = false to prevent UI bug
  if (childrenLength === 1) {
    sliderSettings.infinite = false;
    sliderSettings.arrows = false;
  }

  // Don't show arrows when x images wide and x total images
  if (slidesToShow >= childrenLength) {
    sliderSettings.arrows = false;
  }

  return (
    <Slider className={className} ref={ref} {...sliderSettings}>
      {children}
    </Slider>
  );
});

CustomReactSlider.displayName = 'CustomReactSlider';
