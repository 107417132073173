import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '../../config';

// This should be a hook...
export const OnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    let checkForAWIN = undefined;
    const AWINExclusionList = ['/booking/payment', '/booking/confirmation']; // Add to this if AWIN is not to fire.

    if (config['awin.enable']) {
      const triggerAWIN = () => {
        if (!window.AWIN) {
          return;
        }
        clearInterval(checkForAWIN);
        !AWINExclusionList.includes(pathname) ? window.AWIN.Tracking.run() : console.info('AWIN Disabled');
        console.info('No AWIN on window yet, trying again...');
      };
      checkForAWIN = setInterval(triggerAWIN, 99);
    }

    // This clears the interval when the route changes, otherwise they stack up and cause a memory leak
    return () => clearInterval(checkForAWIN);
  }, [pathname]);

  return null;
}
