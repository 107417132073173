const namespace = 'BOOKING/BOOKING/';

export const COMPLETE_BOOKING = namespace + 'COMPLETE_BOOKING';
export const CREATE_BOOKING = namespace + 'CREATE_BOOKING';
export const GET_DEPOSIT = namespace + 'GET_DEPOSIT';
export const SET_DISCOUNT_CODE = namespace + 'SET_DISCOUNT_CODE';
export const SET_DISCOUNT_VALUE = namespace + 'SET_DISCOUNT_VALUE';
export const LOAD_BOOKING = namespace + 'LOAD_BOOKING';
export const STEP_COMPLETED = namespace + 'STEP_COMPLETED';
export const STEP_NOT_COMPLETED = namespace + 'STEP_NOT_COMPLETED';
export const CUSTOM_BOOKING_PAYMENT = namespace + 'CUSTOM_BOOKING_PAYMENT';
export const CUSTOM_BOOKING_PAYMENT_ERR = namespace + 'CUSTOM_BOOKING_PAYMENT_ERR';
export const RESET_STEPS_COMPLETED = namespace + 'RESET_STEPS_COMPLETED';
export const SET_PREVIOUS_BOOKING_ID = namespace + 'SET_PREVIOUS_BOOKING_ID';
export const SET_PREVIOUS_EXTRAS = namespace + 'SET_PREVIOUS_EXTRAS';
