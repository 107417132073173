import { Link } from 'react-router-dom';
import { A } from '../A';
import { useDispatch } from 'react-redux';
import { selectChannel, selectRegion } from '../../redux/search/form/actions';
import PropTypes from 'prop-types';

export const HeaderColumn = ({ section, handleCloseNavbars, channelId }) => {
  const dispatch = useDispatch();

  const getLink = (text, url) => {
    if (!url) return text;

    if (/^http.*/gi.test(url)) {
      // Add stop propagation here otherwise it bubbles up and closes the dropdown before it links
      return (
        <A href={url} onClick={(e) => e.stopPropagation()}>
          {text}
        </A>
      );
    }

    const numberRegex = /\d+/g;
    const regionIdFromUrl = url.match(numberRegex);

    return (
      <Link
        to={url}
        onClick={(e) => {
          e.stopPropagation(); // Stop the nav from calling region/channel
          selectChannel(dispatch, channelId);
          if (regionIdFromUrl && regionIdFromUrl.length > 0) {
            selectRegion(dispatch, regionIdFromUrl[0]);
          }
          handleCloseNavbars(false);
        }}
      >
        {text}
      </Link>
    );
  };

  return (
    <>
      <p className="lead mb-2 megamenu-heading">{section.title}</p>
      <ul className="list-unstyled">
        {section.links.map(({ text, url }, i) => (
          <li key={i} className="mb-3">
            {getLink(text, url)}
          </li>
        ))}
      </ul>
    </>
  );
};

HeaderColumn.propTypes = {
  section: PropTypes.any,
  handleCloseNavbars: PropTypes.func,
  channelId: PropTypes.string,
};
