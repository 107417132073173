import PropTypes from 'prop-types';
import{ Icon } from './Icon';

import { config } from '../../config';

export const Spinner = ({ message = 'Loading, please wait...' }) => (
  <div className="d-flex align-items-center">
    <Icon icon={config['global.spinnerIcon']} size="h1" theme={config['global.spinnerTheme']} className="spin" />
    <p className="ml-2 h3">{message}</p>
  </div>
);

Spinner.propTypes = {
  message: PropTypes.string,
};
