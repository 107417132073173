import { GET_OPERATORS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const operatorsSelector = (state) => state.data.operators.operators;
export const channelOperatorIds = (state) => state.data.operators.channelOperatorIds;
export const operatorDeposits = (state) => state.data.operators.operatorDeposits;

// To be used by other selectors
export const [getIsLoading, getHasFailed, getErrorData] = getRequestStatus(GET_OPERATORS);
export const getIsLoaded = (state, channelId) => !!channelOperatorIds(state)[channelId];

export const getSpecificOperatorName = (state, id) => {
  return state.data.operators.operators[id].name;
};
