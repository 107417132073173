import { config } from '../../../config';
import { arrayFromXtoY } from '../../utils/utils';
import { HOLIDAY_TYPES } from '../../Constants';

export const getNightsOptions = (channelId) => {
  switch (channelId) {
    case HOLIDAY_TYPES.UK_HOLIDAY_PARKS:
      if (config['searchBox.allowFiveDays']) return [2, 3, 4, 5, 6, 7, 10, 11, 14];
      return [2, 3, 4, 6, 7, 10, 11, 14];
    case 'uktouring':
      return arrayFromXtoY(1, 21);
    case 'boats':
      return [3, 4, 5, 6, 7, 8, 9, 10, 11, 14];
    case 'eurocaravan':
      return [3, 4, 7, 14];
    case 'ukcottages':
      return [...arrayFromXtoY(1, 14), 21];
    default:
      return [2, 3, 4, 7, 10, 11, 14];
  }
};

export const parseMonth = (month) => {
  const m = parseInt(month) + 1;
  return m.toString().padStart(2, '0');
};
