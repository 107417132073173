import { useReducer } from 'react';
import { Col, Collapse, Row } from 'react-bootstrap';
import { FooterNavLink } from './FooterNavLink';
import { footerLinks } from '../../../config';
import t from '../../../text';
import { Icon } from '../Icon';

export const FooterNav = () => {
  const sectionKeys = Object.keys(footerLinks);
  const colWidth = parseInt(12 / (sectionKeys.length || 1), 10);

  // Small device toggle states
  const initState = new Array(sectionKeys.length).fill(false);

  const [toggleState, updateToggleState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initState,
  );

  if (!sectionKeys.length) return null;

  return (
    <Row>
      {sectionKeys.map((sectionKey, i) => (
        <Col md={colWidth} key={sectionKey}>
          <div className="d-block d-md-none">
            <span
              onClick={() => updateToggleState((toggleState[i] = !toggleState[i]))}
              aria-controls={`nav-toggle-${i}`}
              aria-expanded={toggleState[i]}
              className="h5 text-white my-3 d-block cursor-pointer"
            >
              <strong>{t(footerLinks[sectionKey].title)}</strong>
              <Icon icon={`${toggleState[i] ? 'up' : 'down'}`} className="float-right" />
            </span>
            <Collapse in={toggleState[i]}>
              <div id={`nav-toggle-${i}`} className="mb-3">
                {footerLinks[sectionKey].links.map((link) => (
                  <FooterNavLink key={link.id} {...link} />
                ))}
              </div>
            </Collapse>
          </div>
          <div className="d-none d-md-block">
            <p className="text-white h5">
              <strong>{t(footerLinks[sectionKey].title)}</strong>
            </p>
            {footerLinks[sectionKey].links.map((link) => (
              <FooterNavLink key={link.id} {...link} />
            ))}
          </div>
        </Col>
      ))}
    </Row>
  );
};
