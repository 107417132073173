import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { config } from '../../../config';
import { lateDealsSelector } from '../../redux/data/cachedAvail/selectors';
import { filterCachedAvailLateDeals } from '../../utils/cachedAvail';
import { DDMMYYYYToDate, getDayMonthYearFromDate, getDayNameFromDate } from '../../utils/date';

export const OfferBoxes = () => {
  const _lateDeals = useSelector(lateDealsSelector);
  const dealsByDuration = filterCachedAvailLateDeals(_lateDeals);

  return (
    <Container className={`p-0 py-3 bg-white`} fluid>
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className={`column-title text-center my-3 text-darker`}>
              Our Last Minute <strong>Deals</strong>
            </h2>
          </Col>
        </Row>
        {dealsByDuration &&
          Array.from(dealsByDuration.keys()).map(
            (duration) =>
              (duration === 3 || duration === 4 || duration === 7) && (
                <div key={duration} className="mb-4">
                  <Row>
                    {dealsByDuration.get(duration).map((item, index) => {
                      const dayName = getDayNameFromDate(DDMMYYYYToDate(item.date), true);
                      const date = getDayMonthYearFromDate(DDMMYYYYToDate(item.date), true);
                      return (
                        <Col key={index} xs={12} md={6} lg={4}>
                          <div className="d-flex offerBox border h-100">
                            <img
                              className="offerBox-img"
                              src={`//content.breakfreeholidays.co.uk/images/parks/${Array.from(item.park)[0]}/${
                                item.park
                              }/gallery/${item.park}-01.jpg`}
                              alt={item.park}
                              loading="lazy"
                            />
                            <div className="offerBox-body">
                              <h3 className={`h5 m-0 font-weight-bold text-${config['global.textHighlight']}`}>
                                {dayName} {date}
                              </h3>
                              <p
                                className={`m-0 text-${config['global.textBlackHighlight']}`}
                                style={{ color: 'black' }}
                              >
                                {duration} nights
                              </p>
                              <a
                                className={`btn btn-primary d-block py-1 mr-0 ml-auto smallText mt-auto`}
                                href={`/results/all-regions?channel=ukcaravan&region=all-regions&date=${item.date}&nights=${duration}&adults=2&children=0&infants=0&dogs=`}
                              >
                                From <b>£{`${item.price.split('.')[0]}`}</b>
                              </a>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ),
          )}
      </Container>
    </Container>
  );
}
