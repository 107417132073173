import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import RedEye from '../RedEye/RedEye';

import t from '../../../text';
import { stripProtocol } from '../../utils/string';
import { NewsletterHOC, NewsletterProps } from './NewsletterHOC';

function Newsletter({
  hasSignedUpForDeals,
  success,
  validated,
  handleSubmit,
  fName,
  setFName,
  email,
  setEmail,
  message,
  displayRedEye,
}) {
  // We also use success here otherwise the component will close with no message
  // This way it will display success message and then on future visits will not be shown
  if (hasSignedUpForDeals && !success) return null;
  if (!config['forms.enableNewsletterSignup']) return null;

  return (
    <Container fluid className={`py-4 bg-${config['footer.newsletter.bgColour']}`} id="newsletter">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <h2 className="text-white">{t('footer.newsletter.title')}</h2>
            <p>
              <Link to="/terms" className="text-white">
                <u>{t('footer.newsletter.termsAndConditions')}</u>
              </Link>{' '}
              {t('footer.newsletter.apply')}
            </p>
          </Col>
          <Col xs={12} md={6} className="d-flex align-items-center">
            <Form className="flex-fill" noValidate validated={validated} onSubmit={handleSubmit}>
              {!success && (
                <Form.Row>
                  <Col lg={4}>
                    <Form.Group controlId="FirstName">
                      <Form.Label className="text-white">{t('footer.newsletter.firstName')}</Form.Label>
                      <Form.Control
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        type="text"
                        placeholder="First Name"
                        className="border-0"
                        required
                      />
                      <Form.Control.Feedback type="invalid" className="text-white">
                        {t('footer.newsletter.invalidFirstName')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group controlId="EmailAddress">
                      <Form.Label className="text-white">{t('footer.newsletter.emailAddress')}</Form.Label>
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email address"
                        className="border-0"
                        required
                      />
                      <Form.Control.Feedback type="invalid" className="text-white">
                        {t('footer.newsletter.invalidEmail')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={4} className="d-flex align-items-end">
                    <Button
                      block
                      variant={config['footer.newsletter.bgColour'] === 'dark-blue' ? 'secondary' : 'primary'}
                      className="mb-3"
                      type="submit"
                    >
                      {t('footer.newsletter.subscribe')}
                    </Button>
                  </Col>
                </Form.Row>
              )}
              {message.length > 0 && (
                <Form.Row className="justify-content-md-center">
                  <Col>
                    <p className="text-center mt-2">
                      <i className="icon-attention text-white h4" /> {message}
                    </p>
                  </Col>
                </Form.Row>
              )}

              {success && (
                <Form.Row className="justify-content-md-center">
                  <Col xs={12}>
                    <p className="h2 text-white text-center">
                      <i className="icon-check text-success circle-white" /> {t('footer.newsletter.success')}
                    </p>
                  </Col>
                </Form.Row>
              )}

              {displayRedEye && success && (
                <RedEye
                  params={{
                    nourl: 'email-sign-up',
                    newsletter: 'sign-up',
                    email: email,
                    emailpermit: success ? 'yes' : 'no',
                    email_signup_url: stripProtocol(window.location.href),
                  }}
                />
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default NewsletterHOC(Newsletter);

Newsletter.propTypes = NewsletterProps;
