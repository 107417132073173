import { defaultConfig, getApi, getContentRoutes, getFooterLinks, getHeaderLinks } from '../../base/defaultConfig';
import { slugify } from '../../base/utils/string';

import logoDark from './images/logo-dark.svg';
import logoLight from './images/logo-new-style.svg';

export const config = {
  ...defaultConfig,
  'api.url': '',
  'awin.enable': true,
  'cms.url': '/wp-json/wp/v2',
  'global.brandName': 'Breakfree Holidays',
  'global.email': 'enquiries@breakfreeholidays.co.uk',
  'global.siteUrl': 'https://www.breakfreeholidays.co.uk',
  'google.gtm': 'GTM-NQN6259',
  'google.ua': 'UA-485424-64',
  'searchBox.allowFiveDays': true,
  'header.logo.height': 32,
  'socialMedia.facebook': 'https://www.facebook.com/breakfreeholidays/',
  'socialMedia.instagram': 'https://www.instagram.com/breakfreeholidaysofficial/',
  'socialMedia.telephone': 'tel:03448484534',
  'socialMedia.twitter': 'https://twitter.com/BreakFreeHols',
  'socialMedia.youtube': 'https://www.youtube.com/user/BreakFreeHolidays',
  'socialMedia.tiktok': 'https://www.tiktok.com/@breakfree.holidays',
  'socialMedia.linkedin': 'https://www.linkedin.com/company/breakfree-holidays',
  'user.optin.postOpt': true,
  'user.optin.thirdOpt': false,
  'payment.enable3DSecure': true,
  //'payment.payit': true,
  siteCode: 'BF',
};

export const contentRoutes = [
  ...getContentRoutes(config),
  { path: '/privacy-policy', url: 'age/3' },
  { path: '/customer-charter', url: 'age/84' },
  { path: '/offers', url: 'age/221' },
  { path: `/${slugify(config['global.brandName'])}-offers`, url: 'age/221' },
  { path: '/about-us', url: 'age/254' },
  { path: '/cottages', url: 'age/662' },
  { path: '/european-holiday-parks', url: 'age/1572' },
  { path: '/hotels', url: 'age/664' },
  { path: '/cheap-caravan-holidays-uk-europe', url: 'age/225' },
  { path: '/lodges-with-hot-tubs', url: 'age/2263' },
];

const footerData = getFooterLinks(config);
export const footerLinks = {
  ...footerData,
  findAHoliday2: {
    ...footerData.findAHoliday2,
    links: [
      ...footerData.findAHoliday2.links,
      // { id: 'lodgesWithHotTubs', title: 'Lodges with Hot Tubs', path: '/lodges-with-hot-tubs' },
      // { id: 'ukSpaBreaks', title: 'UK Spa Breaks', path: '/uk-spa-breaks' },
      // { id: 'weekendBreaks', title: 'Weekend Breaks', path: '/weekend-breaks' },
      // { id: 'UKFamilyHolidays', title: 'UK Family Holidays', path: '/a/2399/uk-family-holidays' },
      // { id: 'UKRomanticGetaways', title: 'UK Romantic Getaways', path: '/a/2395/uk-romantic-getaways' }
      { id: 'shortUkBreaks', title: 'Short UK Breaks', path: '/a/2522/short-uk-breaks' },
      {
        id: 'summerHolidaysInTheUK',
        title: 'Summer Holidays in the UK',
        path: '/a/2647/summer-holidays-in-the-uk',
      },
      { id: '2838', title: 'Luxury Lodges By The Sea', path: '/a/2838/luxury-lodges-by-the-sea' },
      { id: '2842', title: 'Luxury UK Breaks', path: '/a/2842/luxury-uk-breaks' },
      { id: '2800', title: 'UK Coastal Breaks', path: '/a/2800/uk-coastal-breaks' },
      { id: '2811', title: 'UK Easter Breaks', path: '/a/2811/uk-easter-breaks' },
      { id: '2827', title: 'Family Lodges UK', path: '/a/2827/family-lodges-uk' },
      { id: '2756', title: 'February Half Term Breaks', path: '/a/2756/february-half-term-breaks' },
      { id: '2831', title: 'October Half Term Breaks', path: '/a/2831/october-half-term-breaks' },
    ],
  },
  information: {
    ...footerData.information,
    links: [
      ...footerData.information.links,
      {
        id: 'warner',
        title: 'Warner discount code',
        path: 'https://www.breakfreeholidays.co.uk/blog/warner-leisure-hotels-discount-code-vouchers-exclusive-savings/',
        external: true,
      },
    ],
  },
};

export const images = {
  headerLogoDark: logoDark,
  headerLogoLight: logoLight,
  logoDark: logoDark,
  logoLight: logoLight,
};

export const urls = {
  ...getApi(config, 'api'),
};

export const HeaderLinks = getHeaderLinks([1, 2, 3, 4, 5, 6, 7]);
