import { useState } from 'react';
import { Dropdown, Col, Tab, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ITEM_CLASSES = ['cursor-pointer', 'd-flex', 'justify-content-between', 'text-lg', 'p-3'].join(' ');

export const TabNavigation = ({ active, tabs, content }) => {
  const [activeTab, setActiveTab] = useState(active || tabs[0]);

  const handleMenuClick = (key) => {
    setActiveTab(key);
  };

  return (
    <Tab.Container id="left-tabs" defaultActiveKey={tabs[0].key}>
      <Col md={3}>
        <Nav className="vertical-tab-menu stick-to-top-of-column d-none d-md-block" as="ul">
          {tabs.map((item) => (
            <Nav.Link
              key={item.key}
              as="li"
              onClick={() => handleMenuClick(item.key)}
              eventKey={item.key}
              className={ITEM_CLASSES}
            >
              {item.name}
              <i className="text-lg icon-right text-white d-none d-md-inline-block" />
            </Nav.Link>
          ))}
        </Nav>
        {/* Visible only on xs */}
        <Dropdown className="vertical-tab-menu-sm mb-5 d-block d-md-none">
          <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown border" role="button">
            <span>{tabs.find((obj) => obj.key === activeTab).name}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {tabs.map((item) => (
              <Dropdown.Item key={item.key} onClick={() => handleMenuClick(item.key)} eventKey={item.key}>
                {item.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col md={9}>{content(activeTab)}</Col>
    </Tab.Container>
  );
};

TabNavigation.propTypes = {
  active: PropTypes.string,
  tabs: PropTypes.array,
  content: PropTypes.func,
};
