import PropTypes from 'prop-types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePageWidth } from '../../hooks/usePageWidth';
import { CustomReactSlider } from '../Slider/CustomReactSlider';

const GALLERY_SETTINGS = {
  className: 'three-part-slider',
  lazyLoad: true,
  infinite: true,
  centerPadding: '0',
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        className: '',
        slidesToShow: 1,
      },
    },
  ],
};

export const ThreePartSlider = ({ images, alt, onClick, className = '', children }) => {
  const { width } = usePageWidth();
  const isDesktop = width >= 960;

  // Store them like this so we can go from mobile to desktop and still have the feature work
  const baseDesktopSlides = GALLERY_SETTINGS.slidesToShow;
  const baseMobilesSlides = GALLERY_SETTINGS.responsive[0].settings.slidesToShow;

  // The following code removes any broken images
  const [imageErrors, setImageErrors] = useState(new Set());

  const handleImageError = useCallback((index) => {
    setImageErrors((prevErrors) => new Set(prevErrors).add(index));
  }, []);

  const filteredImages = useMemo(
    () =>
      images
        .map(
          (img, i) =>
            !imageErrors.has(i) && (
              <div className="slider-image-container" key={i + 1}>
                <img
                  className="expanded-centered-image"
                  src={img}
                  alt={`${alt} ${i + 1}`}
                  loading="lazy"
                  onError={() => handleImageError(i)}
                />
              </div>
            ),
        )
        .filter(Boolean),
    [images, alt, imageErrors, handleImageError],
  );

  // The following code handles having 1, 2 or 3 items, all of which will usually be suboptimal on a Slider
  const currentSlidesToShow = isDesktop ? baseDesktopSlides : baseMobilesSlides;
  const slidesToShow = Math.min(currentSlidesToShow, filteredImages.length);

  // Prepare the gallery settings
  const gallerySettings = {
    ...GALLERY_SETTINGS,
    slidesToShow,
  };

  if (filteredImages.length === 0) return null;

  return (
    <Container className={`mb-3 ${className}`} fluid onClick={onClick}>
      <Row className="mb-2 mb-md-5">
        <Col>
          <Suspense fallback={<img src="/img-loading.svg" width="100%" alt="Loading" />}>
            <CustomReactSlider {...gallerySettings}>{filteredImages}</CustomReactSlider>
          </Suspense>
        </Col>
      </Row>
      {children}
    </Container>
  );
};

ThreePartSlider.propTypes = {
  images: PropTypes.array,
  alt: PropTypes.string,
};
