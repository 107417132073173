import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { CustomNextButton, CustomPreviousButton } from '../DatePicker/DatePicker';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date();

export const YearMonthForm = ({ date, localeUtils, onChange, ageGroup }) => {
  const months = localeUtils.getMonths();

  // Use appropriate years for age group
  let minusYears = 100;
  switch (ageGroup) {
    case 'under5s':
      minusYears = 5;
      break;
    case 'children':
      minusYears = 17;
      break;
    default:
      minusYears = 100;
      break;
  }
  const fromYear = new Date(currentYear - minusYears, 0);

  const years = [];
  for (let i = fromYear.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()} className="border">
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()} className="border">
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

export const DateInput = ({
  controlId,
  label,
  invalid,
  hideLabel,
  placeholder,
  value,
  validationMessage,
  name,
  groupClassName = '',
  labelClassName = '',
  className = 'col form-control',
  onChange = () => {},
  attrs = {},
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedDay, setSelectedDay] = useState(value ? new Date(value) : undefined);
  const [inputValue, setInputValue] = useState(value || '');

  const handleDayChange = (day) => {
    setSelectedDay(day);
    const formattedDate = format(day, 'dd/MM/yyyy'); // Change format as needed
    setInputValue(formattedDate);
    onChange(name, formattedDate); // Update parent with the formatted date
    setShowDropdown(false);
  };

  // Function to toggle the DayPicker dropdown
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Form.Group className={groupClassName} controlId={controlId || name} style={{ position: 'relative' }}>
      {label && <Form.Label className={`${labelClassName} ${hideLabel ? 'invisible' : ''}`}>{label}</Form.Label>}

      <div className={`${invalid ? 'is-invalid' : ''}`}>
        <input
          className={`datepicker-input ${className} ${invalid ? 'is-invalid' : ''}`}
          name={name}
          placeholder={placeholder}
          value={inputValue}
          onClick={toggleDropdown}
          onChange={(e) => setInputValue(e.target.value)}
          {...attrs}
        />
        {showDropdown && (
          <div
            className="datepicker-dropdown"
            style={{
              position: 'absolute',
              top: '100%',
              left: '0',
              zIndex: '1000',
              backgroundColor: 'white',
              border: '1px solid #ddd',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              marginTop: '5px',
              padding: '8px',
            }}
          >
            <DayPicker
              mode="single"
              selected={selectedDay}
              onSelect={(day) => handleDayChange(day)}
              startMonth={fromMonth}
              endMonth={toMonth}
              disabled={{
                after: new Date() // Disable all dates after today
              }}
              captionLayout="dropdown"
              components={{
                PreviousMonthButton: CustomPreviousButton,
                NextMonthButton: CustomNextButton,
              }}
            />
          </div>
        )}
      </div>

      {invalid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
};

DateInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
  dob: PropTypes.bool,
};
