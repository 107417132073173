import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import ScrollToTop from '../ScrollToTop';
import { Spinner } from '../Spinner';
import t from '../../../text';

const Wrap = ({ children, useContainer }) => (
  <>
    {useContainer ? (
      <Container className="mt-3 mb-5">
        <Row>
          <Col xs="12">{children}</Col>
        </Row>
      </Container>
    ) : (
      <>{children}</>
    )}
  </>
);

export const WithLoadingStates = ({
  loadingErrorMessage,
  onRetry,
  scrollToTop,
  spinnerMessage,
  hasErrorFetching = false,
  isFetching = false,
  useContainer = false,
  children,
}) => (
  <>
    {isFetching ? (
      <Wrap useContainer={useContainer}>
        {scrollToTop && <ScrollToTop />}
        <Spinner message={spinnerMessage} />
      </Wrap>
    ) : (
      <>
        {!hasErrorFetching ? (
          children
        ) : (
          <Wrap useContainer={useContainer}>
            <p className="text-danger">
              <i className="icon-close text-danger mr-2" />
              {loadingErrorMessage || t('global.errorLoading')}
            </p>
            <button className="btn btn-primary" onClick={onRetry}>
              {t('buttons.retry')}
            </button>
          </Wrap>
        )}
      </>
    )}
  </>
);

WithLoadingStates.propTypes = {
  hasErrorFetching: PropTypes.bool,
  isFetching: PropTypes.bool,
  loadingErrorMessage: PropTypes.string,
  onRetry: PropTypes.func,
  scrollToTop: PropTypes.bool,
  spinnerMessage: PropTypes.string,
  useContainer: PropTypes.bool,
  children: PropTypes.node,
};
