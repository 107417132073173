import { App } from './base/App';
import ErrorBoundary from './base/ErrorBoundary';
import './base/index.css';
import { Maintenance } from './base/maintenance';
import * as serviceWorker from './base/serviceWorker';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux';

import rootReducer from './base/redux/Reducers/Reducer';

import { initGTM } from './base/utils/tracking';
import { config as siteConfig } from './config';

import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { doesCookieExist } from './base/utils/cookies';

if (typeof siteConfig['google.gtm'] !== 'undefined') initGTM(siteConfig['google.gtm']);

const ENABLE_MAINTENANCE_MODE = false;

const store = createStore(rootReducer);

const root = createRoot(document.getElementById('root'));

root.render(
  ENABLE_MAINTENANCE_MODE && !doesCookieExist('GrantUserAccess=') ? (
    <StrictMode>
      <Maintenance />
    </StrictMode>
  ) : (
    <StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </HelmetProvider>
    </StrictMode>
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
