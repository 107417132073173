import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

const INPUT_GROUP_STYLES = { width: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center' };
const INCREMENTS_PER_SECOND = 15;
const INITIAL_DELAY = 300;

export const InputNumber = ({
  onChange,
  value = 0,
  id = 'not-set',
  min = 0,
  max = 10,
  enableTyping = true,
  disableAutoIncrement = false,
  disabled,
}) => {
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  const inc = () => {
    if (disabled) return;

    if (disableAutoIncrement) {
      onChange(Math.min(value + 1, max));
      return;
    }

    onChange((prevValue) => {
      if (prevValue < max) {
        return prevValue + 1;
      }
      return prevValue;
    });
  };

  const dec = () => {
    if (disabled) return;

    if (disableAutoIncrement) {
      onChange(Math.max(value - 1, min));
      return;
    }

    onChange((prevValue) => {
      if (prevValue > min) {
        return prevValue - 1;
      }
      return prevValue;
    });
  };

  // Both of these functions fire on touch, so we only add action() to one of them, otherwise it will fire twice
  // On holding touch down only the touchAction setInterval is fired
  // On desktop (click events) only the top function fires
  const mouseAction = (action) => {
    action();

    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(action, 1000 / INCREMENTS_PER_SECOND);
    }, INITIAL_DELAY);
  };

  const touchAction = (action) => {
    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(action, 1000 / INCREMENTS_PER_SECOND);
    }, INITIAL_DELAY);
  };

  const stopAction = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
  };

  const change = (event) => {
    if (!enableTyping || !onChange) {
      return;
    }
    if (event.target.value === '') return onChange(min);
    const value = parseInt(event.target.value);
    if (isNaN(value)) return;
    if (value > max) return onChange(max);
    if (value < min) return onChange(min);
    onChange(value);
  };

  return (
    <InputGroup>
      <InputGroup.Prepend
        onMouseDown={() => mouseAction(dec)}
        onMouseUp={stopAction}
        onMouseLeave={stopAction}
        onTouchStart={() => touchAction(dec)}
        onTouchEnd={stopAction}
        className="cursor-pointer"
        disabled={value <= min || disabled}
      >
        <InputGroup.Text style={INPUT_GROUP_STYLES}>
          <strong>-</strong>
        </InputGroup.Text>
      </InputGroup.Prepend>

      <FormControl
        value={value}
        id={id}
        disabled={!enableTyping}
        onChange={change}
        className="text-center"
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
      />

      <InputGroup.Append
        onMouseDown={() => mouseAction(inc)}
        onMouseUp={stopAction}
        onMouseLeave={stopAction}
        onTouchStart={() => touchAction(inc)}
        onTouchEnd={stopAction}
        className="cursor-pointer"
        disabled={value >= max || disabled}
      >
        <InputGroup.Text style={INPUT_GROUP_STYLES}>
          <strong>+</strong>
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};

InputNumber.propTypes = {
  value: PropTypes.number,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  enableTyping: PropTypes.bool,
  disableAutoIncrement: PropTypes.bool,
};
