import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import t from '../../../text';
import mastercard from '../../assets/images/mastercard-white.svg';
import paypal from '../../assets/images/paypal-white.svg';
import visa from '../../assets/images/visa-white.svg';
import { Newsletter, Reviews, SocialMedia } from './';
import { CookieConsent } from './CookieConsent';
import { FooterNav } from './FooterNav';

export const Footer = () => {
  const location = useLocation();

  return (
    <footer className="pt-5" id="footer">
      <CookieConsent />
      {!location.pathname.includes('/booking/') && (
        <>
          <Newsletter />
          <Container fluid className="bg-dark py-4">
            <Reviews />
          </Container>

          <Container fluid className="bg-dark border-top border-darker">
            <Container>
              <Row>
                <Col className="col-four-fifths py-4">
                  <FooterNav />
                </Col>
                <Col className="col-one-fifth footer-social-media">
                  <SocialMedia />

                  <p className="text-white h5">
                    <strong>{t('footer.trust.securePaymentOptions')}</strong>
                  </p>
                  <p>
                    <small>{t('footer.trust.securePaymentOptionsText')}</small>
                  </p>
                  <img src={visa} height={16} alt="Visa" className="mr-2" />
                  <img src={mastercard} height={16} alt="Mastercard" className="mr-2" />
                  <img src={paypal} height={16} alt="PayPal" />
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      )}
      <Container fluid className="bg-darker">
        <Container>
          <Row>
            <Col className="p-3">
              <p className="m-0">
                <small>{t('footer.nav.tagline')}</small>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
};
