import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../text';
import {
  selectGuestsAdults,
  selectGuestsChildren,
  selectGuestsUnder5s,
  selectPetFriendly,
} from '../../redux/search/form/actions';
import {
  searchFormAdultsSelector,
  searchFormChannelIdSelector,
  searchFormChildrenSelector,
  searchFormPetFriendlySelector,
  searchFormUnder5sSelector,
} from '../../redux/search/form/selectors';
import { shouldItPluralize } from '../../utils/utils';
import { InputNumber } from '../FormElements';
import { Icon } from '../Icon';

const MAX_GUESTS = 66;

export const GuestsSelect = ({ textStyle = 'white' }) => {
  const dispatch = useDispatch();
  const adults = useSelector(searchFormAdultsSelector);
  const children = useSelector(searchFormChildrenSelector);
  const under5s = useSelector(searchFormUnder5sSelector);
  const petFriendly = useSelector(searchFormPetFriendlySelector);
  const selectedChannelId = useSelector(searchFormChannelIdSelector);

  // The site has to filter a lot of data when any of these change
  // So we set these into temp state and send them to redux when the dropdown closes
  // This is to prevent site stalling on each guest change
  const [adultsUI, setAdultsUI] = useState(adults);
  const [childrenUI, setChildrenUI] = useState(children);
  const [under5sUI, setUnder5sUI] = useState(under5s);
  const [petFriendlyUI, setPetFriendlyUI] = useState(petFriendly);
  const showPetToggle = selectedChannelId !== 'eurocaravan';
  const showHasSelectedDogText = showPetToggle && petFriendlyUI;

  const onAdultsChange = (num) => selectGuestsAdults(dispatch, num);
  const onChildrenChange = (num) => selectGuestsChildren(dispatch, num);
  const onUnder5sChange = (num) => selectGuestsUnder5s(dispatch, num);
  const onPetFriendlyChange = (bool) => selectPetFriendly(dispatch, bool);

  const guestTotal = adultsUI + childrenUI + under5sUI;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      onCloseFunction();
    }
  };

  // When the dropdown closes update each redux guest state,
  // but for performance check each has changed before doing so
  const onCloseFunction = () => {
    if (adultsUI !== adults) onAdultsChange(adultsUI);
    if (childrenUI !== children) onChildrenChange(childrenUI);
    if (under5sUI !== under5s) onUnder5sChange(under5sUI);
    if (petFriendlyUI !== petFriendly) onPetFriendlyChange(petFriendlyUI);
  };

  useEffect(() => {
    // When doing a 0 guest search, 1 adult is included, this is needed to handle that
    if (adults !== adultsUI) {
      setAdultsUI(adults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adults]);

  const guestsInputText =
    guestTotal === 0
      ? 'Add guests'
      : `${guestTotal} ${shouldItPluralize('guest', guestTotal)} ${showHasSelectedDogText ? '+ dogs' : ''}`;

  return (
    <Form.Group>
      <Form.Label className={`text-${textStyle}`}>
        <span>{t('search.yourParty')}</span>
      </Form.Label>
      <Dropdown onToggle={handleToggle} show={isOpen}>
        <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown" role="button" tabIndex="0">
          <span>{guestsInputText}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="a-popup" onMouseLeave={onCloseFunction}>
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.adults')}</p>
            </Col>
            <Col xs={6} className="pl-1">
              <InputNumber
                id="searchbox-guests-adults"
                value={adultsUI}
                min={selectedChannelId === 'boats' ? 2 : 1}
                max={MAX_GUESTS - (childrenUI + under5sUI)}
                onChange={(num) => setAdultsUI(num)}
              />
            </Col>
            {selectedChannelId === 'boats' && (
              <Col>
                <Icon icon="attention" size="text-sm" className="mr-1" />
                <small>Min. 2 adults for Boating holidays.</small>
              </Col>
            )}
          </Row>
          <Dropdown.Divider />
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.children')}</p>
            </Col>
            <Col xs={6} className="pl-1">
              <InputNumber
                id="searchbox-guests-children"
                value={childrenUI}
                max={MAX_GUESTS - (adultsUI + under5sUI)}
                onChange={(num) => setChildrenUI(num)}
              />
            </Col>
          </Row>
          <Dropdown.Divider />
          <Row className="p-3 d-flex align-items-center">
            <Col xs={6}>
              <p className="m-0">{t('search.infants')}</p>
            </Col>
            <Col xs={6} className="pl-1">
              <InputNumber
                id="searchbox-guests-under5s"
                value={under5sUI}
                max={MAX_GUESTS - (adultsUI + childrenUI)}
                onChange={(num) => setUnder5sUI(num)}
              />
            </Col>
          </Row>
          {showPetToggle && (
            <>
              <Dropdown.Divider />
              <Row className="p-3 d-flex align-items-center">
                <Col xs={9}>
                  <label
                    htmlFor="petFriendly"
                    className="pet-friendly my-0 cursor-pointer text-darker font-weight-normal"
                  >
                    {t('search.showMePetFriendly')}
                  </label>
                </Col>
                <input
                  type="checkbox"
                  name="petFriendly"
                  id="petFriendly"
                  className="cursor-pointer mr-3 mr-md-0"
                  checked={petFriendlyUI}
                  onChange={(event) => setPetFriendlyUI(event.target.checked)}
                />
              </Row>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

GuestsSelect.propTypes = {
  textStyle: PropTypes.string,
  pet: PropTypes.string,
  onPetChange: PropTypes.number,
};
