import { usePageWidth } from '../../hooks/usePageWidth';

export const useGetCurrentSlides = () => {
  const { width } = usePageWidth();

  const getCurrentSlides = (settings) => {
    // if no responsive, then return default amount
    if (!settings.responsive) return settings.slidesToShow;
    // Loop through responsive, starting from the back, and find the first appropriate breakpoint
    for (let i = settings.responsive.length; i > 0; i--) {
      if (settings.responsive[i - 1].breakpoint > width) {
        return settings.responsive[i - 1].settings.slidesToShow;
      }
    }
    return settings.slidesToShow;
  };

  return { getCurrentSlides };
};
