const namespace = 'SEARCH/FORM/';

export const SELECT_CHANNEL = `${namespace}SELECT_CHANNEL`;
export const SELECT_REGION = `${namespace}SELECT_REGION`;
export const SELECT_DATE = `${namespace}SELECT_DATE`;
export const SELECT_DURATION = `${namespace}SELECT_DURATION`;
export const SELECT_MONTH = `${namespace}SELECT_MONTH`;
export const SELECT_GUESTS_ADULTS = `${namespace}SELECT_GUESTS_ADULTS`;
export const SELECT_GUESTS_CHILDREN = `${namespace}SELECT_GUESTS_CHILDREN`;
export const SELECT_GUESTS_UNDER5S = `${namespace}SELECT_GUESTS_UNDER5S`;
export const SELECT_PET_FRIENDLY = `${namespace}SELECT_PET_FRIENDLY`;
export const START_SEARCH = `${namespace}START_SEARCH`;
export const STORE_COTTAGE_DATES = `${namespace}STORE_COTTAGE_DATES`;
export const RESET_ARRIVAL = `${namespace}RESET_ARRIVAL`;
export const INCREASE_ADULTS_IF_NONE = `${namespace}INCREASE_ADULTS_IF_NONE`;
