import { config, images } from '../../config';

export const Logo = ({
  alt = config['global.brandName'],
  variant = 'light',
  logoLight = images.logoLight,
  logoDark = images.logoDark,
  height = 32,
  className = '',
}) => <img src={variant === 'dark' ? logoDark : logoLight} height={height} className={className} alt={alt} />;
