import { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAdjustedDateForBST } from '../../utils/utils';

const BASE_DATE_OBJECT = { d: 0, h: 0, m: 0, s: 0 };

export const Countdown = ({
  datetime,
  title,
  text,
  linktext,
  url,
  status,
  background_colour = 'darker',
  text_colour = 'lighter',
}) => {
  // Datetime is given to us / 1000
  const dateWithBSTRemoved = useMemo(() => getAdjustedDateForBST(new Date(datetime * 1000)), [datetime]);

  const [remainingTime, setRemainingTime] = useState(BASE_DATE_OBJECT);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const end = dateWithBSTRemoved;
      const dif = (end - now) / 1000;

      if (dif > 0) {
        return {
          d: Math.floor(dif / (60 * 60 * 24)),
          h: Math.floor((dif / (60 * 60)) % 24),
          m: Math.floor((dif / 60) % 60),
          s: Math.floor(dif % 60),
        };
      }
      return BASE_DATE_OBJECT;
    };

    // Run on first load
    setRemainingTime(calculateTimeLeft());

    // Then each second afterwards
    const timer = setInterval(() => {
      setRemainingTime(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [dateWithBSTRemoved]);

  // Ensure that at least one value is above 0
  const atLeastOneValidTime = Object.values(remainingTime)?.some((timeUnit) => timeUnit > 0);
  if (!atLeastOneValidTime || !status) return null;

  return (
    <Container fluid className={`bg-${background_colour} text-${text_colour} py-5 border-bottom`}>
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h2 className={`text-${text_colour}`}>{title}</h2>
            <p>{text}</p>
          </Col>
          <Col md={6}>
            <Row className="p-2">
              <Col xs={3} className="px-0">
                <div className="p-2 text-center">
                  <div className="countdown-card bg-white p-2 border rounded">
                    <strong>{remainingTime.d}</strong>
                  </div>
                  <p className="text-uppercase mb-0 mt-2">Days</p>
                </div>
              </Col>
              <Col xs={3} className="px-0">
                <div className="p-2 text-center">
                  <div className="countdown-card bg-white p-2 border rounded">
                    <strong>{remainingTime.h}</strong>
                  </div>
                  <p className="text-uppercase mb-0 mt-2">Hours</p>
                </div>
              </Col>
              <Col xs={3} className="px-0">
                <div className="p-2 text-center">
                  <div className="countdown-card bg-white p-2 border rounded">
                    <strong>{remainingTime.m}</strong>
                  </div>
                  <p className="text-uppercase mb-0 mt-2">
                    Min<span className="d-none d-sm-inline">ute</span>s
                  </p>
                </div>
              </Col>
              <Col xs={3} className="px-0">
                <div className="p-2 text-center">
                  <div className="countdown-card bg-white p-2 border rounded">
                    <strong>{remainingTime.s}</strong>
                  </div>
                  <p className="text-uppercase mb-0 mt-2">
                    Sec<span className="d-none d-sm-inline">onds</span>
                  </p>
                </div>
              </Col>
              <Col xs={12} className="px-2 my-2">
                <a href={url} className="btn btn-lg btn-primary py-2 btn-block">
                  {linktext}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

Countdown.propTypes = {
  datetime: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  linktext: PropTypes.string,
  url: PropTypes.string,
  status: PropTypes.bool,
  background_colour: PropTypes.string,
  text_colour: PropTypes.string,
};
