import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectChannel,
  selectDate,
  selectDuration,
  selectGuestsAdults,
  selectGuestsChildren,
  selectGuestsUnder5s,
  selectMonth,
  selectPetFriendly,
  selectRegion,
} from '../../redux/search/form/actions';

import { setOffers } from '../../redux/offers/actions';
import { selectedOffersSelector } from '../../redux/offers/selectors';
import {
  searchFormAdultsSelector,
  searchFormArrivalDateSelector,
  searchFormChannelIdSelector,
  searchFormChildrenSelector,
  searchFormDurationSelector,
  searchFormMonthSelector,
  searchFormPetFriendlySelector,
  searchFormRegionIdSelector,
  searchFormUnder5sSelector,
} from '../../redux/search/form/selectors';
import { DDMMYYYYToDate } from '../../utils/date';
import { ynToBool } from '../../utils/utils';
import { dateValidator } from '../../utils/validation';

const doArraysMatch = (arr1, arr2) => {
  const isMatch = arr1.every((item) => arr2.includes(item));
  const isExactMatch = isMatch && arr2.length === arr1.length;
  return isExactMatch;
};

export const WithUrlParams = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [readyToDisplay, setReadyToDisplay] = useState(!search);
  const channelId = useSelector(searchFormChannelIdSelector);
  const regionId = useSelector(searchFormRegionIdSelector);
  const arrivalDate = useSelector(searchFormArrivalDateSelector);
  const duration = useSelector(searchFormDurationSelector);
  const _month = useSelector(searchFormMonthSelector);
  const adults = useSelector(searchFormAdultsSelector);
  const children = useSelector(searchFormChildrenSelector);
  const under5s = useSelector(searchFormUnder5sSelector);
  const petFriendly = useSelector(searchFormPetFriendlySelector);
  const selectedOffers = useSelector(selectedOffersSelector);

  const updateQueryParamsIfNecessary = () => {
    const query = new URLSearchParams(search);
    // Holiday search
    if (query.has('channel') && query.get('channel') !== channelId) {
      selectChannel(dispatch, query.get('channel'));
    }
    if (query.has('region') && query.get('region') !== regionId) {
      selectRegion(dispatch, query.get('region'));
    }
    if (query.has('nights') && query.get('nights') !== duration) {
      selectDuration(dispatch, parseInt(query.get('nights')));
    }
    if (query.has('date') && dateValidator(query.get('date'))) {
      const [, month, year] = query.get('date').split('/');
      const newDate = `${year}-${month}`;
      if (newDate !== _month) {
        selectMonth(dispatch, newDate);
      }
      const newArrivalDate = DDMMYYYYToDate(query.get('date'));
      const newDateFormatted = new Date(newArrivalDate)?.toISOString();
      const currentDateFormatted = arrivalDate ? new Date(arrivalDate)?.toISOString() : null;

      if (newDateFormatted !== currentDateFormatted) {
        selectDate(dispatch, newArrivalDate);
      }
    }

    const updateGuests = (param, currentValue, action) => {
      const queryValue = parseInt(query.get(param));
      if (query.has(param) && queryValue !== currentValue && !isNaN(queryValue)) {
        action(dispatch, queryValue);
      }
    };
    
    updateGuests('adults', adults, selectGuestsAdults);
    updateGuests('children', children, selectGuestsChildren);
    updateGuests('infants', under5s, selectGuestsUnder5s);

    const queryDogs = ynToBool(query.get('dogs'));
    if (query.has('dogs') && queryDogs !== petFriendly && typeof queryDogs === 'boolean') {
      selectPetFriendly(dispatch, queryDogs);
    }

    // Handle updating offers in redux based on the url, both adding and removing values
    const urlOffersArray = query.get('offers') ? [...query.get('offers').split(',')] : [];
    if (!doArraysMatch(urlOffersArray, selectedOffers)) {
      setOffers(dispatch, urlOffersArray);
    }
  };

  useEffect(() => {
    if (!search) {
      return;
    }

    setReadyToDisplay(false);
    updateQueryParamsIfNecessary();
    setReadyToDisplay(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  if (!readyToDisplay) {
    return null;
  }

  return props.children;
};
