import PropTypes from 'prop-types';
import { useState } from 'react';
import { urls } from '../../../config';
import { useNewsletter } from './NewsletterContext';

export const NewsletterHOC = (OriginalComponent) => {
  const NewComponent = () => {
    const [validated, setValidated] = useState(false);
    const [fName, setFName] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [displayRedEye, setDisplayRedEye] = useState(false);

    const { hasSignedUpForDeals, setHasSignedUpForDeals } = useNewsletter();

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      if (!hasSignedUpForDeals && form.checkValidity() === true) {
        fetch(`${urls.newsletterSignup}?email=${encodeURIComponent(email)}&fname=${encodeURIComponent(fName)}`)
          .then((response) => response.json())
          .then((data) => {
            const isSuccessful = data.success === 1;

            setSuccess(isSuccessful);
            if (isSuccessful) {
              setMessage('');
              setHasSignedUpForDeals(true);
            }
            if (!isSuccessful) {
              setMessage(data.errors[0].message);
              setDisplayRedEye(true);
            }
          })
          .catch((e) => {
            console.error('Could not fetch the content.', e);
            setDisplayRedEye(true);
          });
      }

      setValidated(true);
      setDisplayRedEye(true);
    };

    return (
      <OriginalComponent
        hasSignedUpForDeals={hasSignedUpForDeals}
        success={success}
        validated={validated}
        handleSubmit={handleSubmit}
        fName={fName}
        setFName={setFName}
        email={email}
        setEmail={setEmail}
        message={message}
        displayRedEye={displayRedEye}
      />
    );
  };
  return NewComponent;
};

export const NewsletterProps = {
  hasSignedUpForDeals: PropTypes.bool,
  success: PropTypes.bool,
  validated: PropTypes.bool,
  handleSubmit: PropTypes.func,
  fName: PropTypes.string,
  setFName: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  message: PropTypes.string,
  displayRedEye: PropTypes.bool,
};
