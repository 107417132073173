import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const TextInput = ({
  controlId,
  invalid,
  placeholder,
  onChange,
  disabled,
  hideLabel,
  label,
  validationMessage,
  name,
  groupClassName = '',
  labelClassName = '',
  className = 'col',
  type = 'text',
  attrs = {},
  value = '',
}) => (
  <Form.Group className={groupClassName} controlId={controlId || name}>
    {label && <Form.Label className={`${labelClassName} ${hideLabel ? 'invisible' : ''}`}>{label}</Form.Label>}

    <Form.Control
      className={`${className} ${invalid ? 'is-invalid' : ''}`}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      {...attrs}
    />

    {invalid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
  </Form.Group>
);

TextInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  validationMessage: PropTypes.string,
};
