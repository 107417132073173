import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';

export const InputCheckbox = ({ onChange, checked, className, ariaLabel, label, disabled, id = 'not-set' }) => {
  const handleChange = onChange && !disabled ? () => onChange(!checked) : undefined;

  const cursorClassName = disabled ? 'cursor-disabled' : 'cursor-pointer';
  return (
    <InputGroup className={className}>
      <InputGroup.Prepend className={cursorClassName} onClick={handleChange}>
        <InputGroup.Checkbox
          id={id}
          className={cursorClassName}
          checked={checked}
          onChange={handleChange}
          aria-label={ariaLabel || label}
          disabled={disabled}
        />
      </InputGroup.Prepend>
      <label htmlFor={id} className="form-control h-auto" dangerouslySetInnerHTML={{ __html: label }} />
    </InputGroup>
  );
};

InputCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
