import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import '../../../'; // Import your styles
import { config } from '../../../config';
import t from '../../../text';
import { A } from '../A';

const SOCIAL_LINKS = [
  { key: 'facebook', Icon: FaFacebookF },
  { key: 'instagram', Icon: FaInstagram },
  { key: 'twitter', Icon: FaXTwitter },
  { key: 'youtube', Icon: FaYoutube },
  { key: 'tiktok', Icon: FaTiktok },
  { key: 'linkedin', Icon: FaLinkedin },
];

export const SocialMedia = () => {
  const availableSocialLinks = SOCIAL_LINKS.filter(({ key }) => config[`socialMedia.${key}`]);

  return (
    <div>
      <p className="text-white h5">
        <strong>{t('footer.reviews.connectWithUs')}</strong>
      </p>

      <div className="social-icons">
        {availableSocialLinks.map(({ key, Icon }) => (
          <div key={key} className="social-icon">
            <A
              className="text-white mb-2 mx-3 icon-hover"
              href={config[`socialMedia.${key}`]}
              title={t(`global.socialMedia.${key}`)}
            >
              <Icon />
            </A>
          </div>
        ))}
      </div>
    </div>
  );
};
